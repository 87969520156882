<template>
    <div class="playlist-wrap">
        <sc-player
            v-if="selectedSermon !== null"
            :sermon="selectedSermon"
            :windowWidth="windowWidth"
        ></sc-player>
        <sc-filters
            v-if="selectedSermon !== null"
            v-on:filtering-requested="filterSermons"
            ref="filtering"
        ></sc-filters>
        <div class="series-view" v-show="loaded">
            <div
                v-for="series in sermonsBySeries"
                :key="'series-' + series.series.slug"
                :class="series.series.slug"
            >
                <h3 v-show="series.sermons.length > 0" class="series-heading">{{ series.series.title }}</h3>
                <div class="grid-view">
                    <sc-playlist-item
                        v-for="sermon in series.sermons"
                        :key="'sermon-' + sermon.slug"
                        :sermon="sermon"
                        v-on:sermon-selected="updatePlayer"
                        v-on:category-selected="categoryFilterSelected"
                        :playable="true"
                        :dark="false"
                        :grid="true"
                        :windowWidth="windowWidth"
                    ></sc-playlist-item>
                </div>
            </div>
        </div>
        <b-pagination
            v-show="loaded && pagination.total > pagination.per_page"
            align="center"
            :hide-goto-end-buttons="true"
            v-model="pagination.current_page"
            :number-of-pages="pagination.last_page"
            :per-page="pagination.per_page"
            :total-rows="pagination.total"
            v-on:change="changePage"
        ></b-pagination>
        <div class="message-wrap" v-show="!loaded && selectedSermon !== null">Loading Content...</div>
        <div class="message-wrap" v-show="selectedSermon === null">We're sorry but there don't seem to be any sermons currently available.</div>
        <div class="message-wrap" v-show="loaded && seriesCount == 0">
            No sermons found... Try a different set of filters
        </div>
    </div>
</template>

<script>
import BPagination from 'bootstrap-vue/es/components/pagination/pagination'
import axiosCancel from 'axios-cancel'

axiosCancel(window.axios, {
    debug: false
})

export default {
    components: { BPagination },
    props: ['embedded'],
    data() {
        return {
            sermonsBySeries: {},
            pagination: {},
            selectedSermon: null,
            loaded: false,
            playlistView: `${window.Church.settings.display_layout}-view`,
            windowWidth: window.innerWidth
        }
    },
    created() {
        this.selectedSermon = window.initial_sermons
        this.updatePagination(window.initial_series)
        this.getSermonsBySeries(window.initial_series.data)
    },
    mounted() {
        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }
    },
    computed: {
        seriesCount() {
            return Object.keys(this.sermonsBySeries).length
        }
    },
    methods: {
        getSermonsBySeries(series, filterQuery = '', page = 1) {
            // We need to remove the series filter because
            // we are setting it directly based on the series
            if (filterQuery.indexOf("&") > -1) {
                let filterRegex = /filters\[series\]=.+?(?=&)/gm
                 filterQuery = filterQuery.replace(filterRegex, '')
            } else {
                let filterRegex = /filters\[series\]=.+/gm
                filterQuery = filterQuery.replace(filterRegex, '')
            }

            series.forEach(series => {
                this.sermonsBySeries[series.slug] = {
                    series: series,
                    sermons: []
                }
                window.axios
                    .get(
                        `//api.${window.base_domain}/${
                            window.Church.slug
                        }/sermons?howmany=100&filters[series]=${
                            series.slug
                        }&filters[order]=series&${filterQuery}&page=${page}`
                    )
                    .then(response => {
                        this.processResponse(series.slug, response.data)
                    })
                    .catch(error => {
                        console.log(`There was an error: ${error.response}`)
                    })
            })
        },
        processResponse(key, sermons) {
            this.sermonsBySeries[key].sermons = sermons.data
            this.$forceUpdate()
            this.loaded = true
            if (this.embedded) {
                window.sermonCloudResizeHeight()
            }
        },
        updatePlayer(sermon) {
            this.selectedSermon = sermon
            window.parent.postMessage(['scrollToTop'], '*')
            this.$refs.filtering.expanded = false
        },
        categoryFilterSelected(category) {
            this.$refs.filtering.filters.series = []
            this.$refs.filtering.filters.speakers = []
            this.$refs.filtering.filters.passages = []
            this.$refs.filtering.filters.categories = [category]
        },
        changePage(page) {
            event.preventDefault
            this.loaded = false
            window.parent.postMessage(['scrollToTop'], '*')
            this.filterSermons(this.$refs.filtering.filters, page)
        },
        updatePagination(paginationData) {
            this.pagination = {
                current_page: paginationData.current_page,
                first_page_url: paginationData.first_page_url,
                from: paginationData.from,
                last_page: paginationData.last_page,
                last_page_url: paginationData.last_page_url,
                next_page_url: paginationData.next_page_url,
                path: paginationData.path,
                per_page: parseInt(paginationData.per_page, 10),
                to: paginationData.to,
                total: parseInt(paginationData.total, 10)
            }
        },
        filterSermons(filters, page = 1) {
            let filterQuery = Object.keys(filters)
                .filter(key => filters[key].length > 0)
                .map(key => `filters[${key}]=${filters[key]}`)
                .join('&')
            this.loaded = false
            this.sermonsBySeries = {}
            window.parent.postMessage(['scrollToTop'], '*')
            window.axios
                .get(
                    `//api.${window.base_domain}/${
                        window.Church.slug
                    }/sermons?howmany=100&page=${page}&groupby=true&${filterQuery}`,
                    {
                        requestId: 'filtering_request'
                    }
                )
                .then(response => {
                    this.updatePagination(response.data)
                    if (filters['series'] && filters['series'].length > 0) {
                        let filteredSeries = [];
                        for (let i = 0; i < window.initial_series.data.length; i++) {
                            const series = window.initial_series.data[i];
                            if (filters['series'].indexOf(series.slug) > -1) {
                                filteredSeries.push(series);
                            }
                        }
                        this.getSermonsBySeries(filteredSeries, filterQuery, page)
                    } else {
                        this.getSermonsBySeries(response.data.data, filterQuery, page)
                    }
                })
                .catch(error => {
                    if (window.axios.isCancel(error)) {
                        // If any filter requests are pending we
                        // cancel them here to keep responses fast
                        return
                    }
                    // We returned 0 sermons from the filters
                    this.loaded = true
                    this.sermons = []
                    this.pagination = {}
                    if (this.embedded) {
                        window.sermonCloudResizeHeight()
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss';
.playlist-wrap {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}
.message-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}
.series-heading {
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0;
    margin: 0;
    color: rgba(31, 33, 38, 1);
}
.grid-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 16px;
    .sermon {
        flex: none;
    }
}
</style>
