<template>
    <div class="sermon" :class="{ dark: dark, grid: grid }">
        <div class="title-wrap">
            <a
                @click="$emit('sermon-selected', sermon)"
                v-if="playable"
                href="#"
                ><h2 class="sermon-title" v-html="sermon.title"></h2
            ></a>
            <h2 v-else class="sermon-title" v-html="sermon.title"></h2>
            <h2
                v-if="sermon.series && sermon.series.title"
                class="series-title"
                v-html="sermon.series.title"
            ></h2>
            <sc-heart
                :class="{ dark: dark, grid: grid }"
                :sermon="sermon"
            ></sc-heart>
            <span
                v-if="hasEventSettings"
                class="event-settings"
            >
                <a
                    @click.prevent="openEventSettings"
                    href="#"
                >
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-from-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-from-right fa-w-14"><path fill="currentColor" d="M448 88v336c0 13.3-10.7 24-24 24h-24c-13.3 0-24-10.7-24-24V88c0-13.3 10.7-24 24-24h24c13.3 0 24 10.7 24 24zM193.5 357.6L127.9 292H328c13.3 0 24-10.7 24-24v-24c0-13.3-10.7-24-24-24H127.9l65.6-65.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L7 239c-9.4 9.4-9.4 24.6 0 33.9l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.3 9.4-24.5.1-33.8z" class=""></path></svg>
                    Event Settings
                </a>
            </span>
            <span
                v-else
                class="media-types"
            >
                <a
                    v-if="(sermon.media.video || sermon.media.embed) && full"
                    :class="{
                        active:
                            selectedType == 'video' || selectedType == 'embed'
                    }"
                    href="#"
                    @click="$emit('media-selected', 'video')"
                    >Video</a
                >
                <span v-if="(sermon.media.video || sermon.media.embed) && !full"
                    >Video</span
                >
                <span
                    v-if="
                        (sermon.media.video || sermon.media.embed) &&
                            sermon.media.audio
                    "
                >
                    +
                </span>
                <a
                    v-if="sermon.media.audio && full"
                    :class="{ active: selectedType == 'audio' }"
                    href="#"
                    @click="$emit('media-selected', 'audio')"
                    >Audio</a
                >
                <span v-if="sermon.media.audio && !full">Audio</span>
            </span>
        </div>
        <div class="title-border"></div>
        <div class="content-wrap">
            <a
                class="image"
                :style="sermonImage"
                @click="$emit('sermon-selected', sermon)"
                v-if="playable"
                href="#"
            >
                <simple-svg
                    :filepath="this.getSvgFilePath('play-outline')"
                    width="44px"
                    height="44px"
                    fill="rgba(255, 255, 255, 1)"
                ></simple-svg>
            </a>
            <div class="content">
                <div class="grid-title-wrap">
                    <div class="grid-title-like-wrap">
                        <a @click="$emit('sermon-selected', sermon)" href="#"
                            ><h2 class="sermon-title" v-html="sermon.title"></h2
                        ></a>
                        <sc-heart
                            :class="{ dark: dark, grid: grid }"
                            :sermon="sermon"
                        ></sc-heart>
                    </div>
                    <h2
                        v-if="sermon.series && sermon.series.title"
                        class="series-title"
                        v-html="sermon.series.title"
                    ></h2>
                </div>
                <div class="meta-wrap">
                    <div class="sermon-info-wrap">
                        <p
                            v-if="sermon.preacher"
                            class="preacher"
                            v-html="sermon.preacher"
                        ></p>
                        <p class="date">{{ sermon.date.date }}</p>
                        <div v-if="sermon.passages">
                            <p class="passages" v-html="sermonPassages"></p>
                        </div>
                        <div v-if="sermon.categories" class="categories-wrap">
                            <button
                                v-for="(category, slug) in sermon.categories"
                                :key="slug"
                                class="badge badge-tag"
                                @click="$emit('category-selected', slug)"
                            >
                                <span v-html="category"></span>
                            </button>
                        </div>
                    </div>
                    <sc-more-info-buttons
                        v-if="full"
                        :sermon="sermon"
                    ></sc-more-info-buttons>
                </div>
                <div class="details-wrap">
                    <div
                        v-if="!full"
                        class="multi-line-truncate"
                    >
                        <div v-html="stripIframes(sermonSummaryText)"></div>
                    </div>
                    <div v-else class="full">
                        <div
                            :class="{'multi-line-truncate': !expanded}"
                            ref="sermonText"
                        >
                            <div v-html="stripIframes(sermonSummaryText)"></div>
                        </div>
                        <div v-show="expanded">
                            <div
                                v-if="
                                    sermon.interactive_note && windowWidth > 768
                                "
                            >
                                <sc-interactive-note
                                    :note="sermon.interactive_note.note"
                                ></sc-interactive-note>
                            </div>
                        </div>
                        <template v-if="shouldDisplayExpandArrow">
                            <a
                                v-show="!expanded"
                                @click="expandCollapse()"
                            >
                                <img
                                    class="expand"
                                    :src="getImageUrl('icon-arrow-down.png')"
                                    alt="expand"
                                />
                            </a>
                            <a v-show="expanded" @click="expandCollapse()">
                                <img
                                    class="expand"
                                    :src="getImageUrl('icon-arrow-up.png')"
                                    alt="collapse"
                                />
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import join from 'lodash/join'

export default {
    props: [
        'sermon',
        'dark',
        'hasEventSettings',
        'playable',
        'full',
        'startExpanded',
        'selectedType',
        'grid',
        'windowWidth'
    ],
    data() {
        return {
            expanded:
                typeof this.startExpanded === 'undefined'
                    ? false
                    : this.startExpanded,
            shouldDisplayExpandArrow: false
        }
    },
    mounted() {
        // Only the "full" summary could get an expand arrow, so don't process the regular playlist items
        if (this.full) {

            // Wait until the DOM is rendered
            this.$nextTick(function() {

                // Set the expand arrow on page load
                this.setShouldDisplayExpandArrow();

                // Recheck the need for an expand arrow when the content resizes
                new ResizeObserver(this.setShouldDisplayExpandArrow).observe(document.querySelector('.content'));
            });
        }
    },
    computed: {
        sermonImage: function() {
            let imageUrl = this.getSvgFilePath('headphones')
            let backgroundSize = '53px'
            if (this.sermon.series && this.sermon.series.image) {
                imageUrl = this.sermon.series.image
                backgroundSize = 'cover'
            }
            if (this.sermon.media.image) {
                imageUrl = this.sermon.media.image
                backgroundSize = 'cover'
            }
            return `
                background-image:url('${imageUrl}');
                background-size: ${backgroundSize};
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 10px;
                box-shadow:  0 4px 10px 0 rgba(0,0,0,0.06);
            `
        },
        sermonSummaryText: function() {
            if (this.sermon.summary) {
                return this.sermon.summary
            }

            if (this.sermon.text) {
                return this.sermon.text
            }

            return ''
        },
        sermonText: function() {
            if (this.sermon.text) {
                return this.sermon.text
            }

            return this.sermonSummaryText
        },
        sermonPassages: function() {
            if (this.sermon.passages.length > 1) {
                return 'Passages: ' + join(this.sermon.passages, ', ')
            }

            if (this.sermon.passages[0]) {
                return 'Passage: ' + this.sermon.passages[0]
            }

            return ''
        }
    },
    methods: {
        setShouldDisplayExpandArrow: function() {
            // If scrollHeight is larger than offsetHeight the text is being truncated
            var isTruncated = this.$refs.sermonText.scrollHeight > this.$refs.sermonText.offsetHeight;

            // Show the expand arrow if
            // - the user already expanded the text
            // - there is an interactive note
            // - the sermon text is being truncated
            this.shouldDisplayExpandArrow = this.expanded || this.sermon.interactive_note || isTruncated;
        },
        getImageUrl: function(image) {
            return  process.env.ASSET_PATH + 'images/' + image
        },
        stripIframes: function(text) {
            let IFRAME_REGEX = new RegExp('(?:<iframe[^>]*)(?:(?:\\/>)|(?:>.*?<\\/iframe>))'),
                match = IFRAME_REGEX.exec(text),
                index,
                len;

            if (!match) {
                return text
            }

            index = match.index
            len = match[0].length

            return text.substring(0, index) + text.substring(index + len)
        },
        expandCollapse: function() {
            if (this.expanded) {
                this.expanded = false;
                window.scrollTo(0,0);
                window.parent.postMessage(['scrollToTop'], '*')

                // If the page sized changed while expanded, the collapsed text may no longer need the expand arrow
                this.setShouldDisplayExpandArrow();
            } else {
                this.expanded = true;
            }
        },
        openEventSettings() {
            window.parent.postMessage(['openEventSettings'], '*')
        }
    },
    watch: {
        sermon: function() {
            this.expanded = false
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss';
.sermon {
    a {
        cursor: pointer;
    }
    margin: 14px 42px 27px;
    min-height: 166px;
    .title-border {
        border: 1px solid rgba(189, 189, 189, 0.74);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
        margin-right: -19px;
        margin-left: -6px;
    }
    .title-wrap {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 5px;
        a,
        a > h2:hover {
            text-decoration: none;
            color: rgba(51, 153, 255, 1);
        }
        h2 {
            color: rgba(59, 62, 68, 1);
            margin-bottom: 0;
        }

        .sermon-title {
            font-size: 18px;
            font-weight: 700;
            margin-right: 20px;
            line-height: 22px;
            max-width: 300px;
        }

        .series-title {
            flex: 1;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }

        .event-settings {
            a {
                align-items: center;
                color: #fff;
                display: flex;
                font-size: 14px;
            }

            svg {
                margin-right: 10px;
                width: 14px;
            }
        }

        .media-types {
            text-transform: uppercase;
            color: rgba(155, 155, 155, 1);
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            letter-spacing: 0.89px;
            a {
                color: rgba(155, 155, 155, 1);
                &.active {
                    font-size: 12px;
                }
            }
        }
    }
    .content-wrap {
        margin-top: 20px;
        line-height: 1em;
        display: flex;
        width: 100%;
        .image {
            min-width: 200px;
            min-height: 120px;
            max-width: 200px;
            max-height: 120px;
            background-color: rgba(200, 200, 200, 1);
            opacity: 0.74;
            margin-right: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 200px;
                max-height: 120px;
            }
            &:hover {
                opacity: 1;
            }
        }
        .grid-title-wrap {
            display: none;
        }
        .content {
            width: 100%;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.13px;
            display: flex;
            flex-direction: column;
            p {
                margin-bottom: 0.5em;
            }
            .meta-wrap {
                display: flex;
                justify-content: space-between;
            }
            .categories-wrap {
                padding-bottom: 15px;
            }
            .details-wrap {
                .summary,
                .full {
                    font-size: 14px;
                    line-height: 17px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    .expand {
                        min-width: 48px;
                        min-height: 48px;
                        max-width: 48px;
                        max-height: 48px;
                        margin-left: 12px;
                    }
                }
            }
        }
    }
    &.grid {
        width: 350px;
        max-width: 350px;
        margin: 0 10px;
        @include media-breakpoint-up(lg) {
            flex: 1;
        }
        .title-wrap,
        .title-border {
            display: none;
        }
        .content-wrap {
            flex-direction: column;
            .grid-title-wrap {
                a,
                a > h2:hover {
                    text-decoration: none;
                    color: rgba(51, 153, 255, 1);
                }
                .grid-title-like-wrap {
                    display: flex;
                    justify-content: space-between;
                }
                display: block;
                h2 {
                    color: rgba(59, 62, 68, 1);
                    margin-bottom: 0;
                }

                .sermon-title {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 22px;
                }

                .series-title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 10px;
                }
            }
            .sermon-info-wrap {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0;
            }
            .image {
                min-width: 350px;
                min-height: 200px;
                max-width: 350px;
                max-height: 200px;
                margin-bottom: 27px;
                margin-right: 0;
                img {
                    max-width: 350px;
                    max-height: 200px;
                }
            }
            .content {
                margin: 0 8px;
                .categories-wrap,
                .details-wrap {
                    display: none;
                }
            }
        }
    }
}
.dark {
    .title-wrap {
        h2 {
            color: rgba(255, 255, 255, 1);
        }
    }
    .content-wrap {
        .image {
            opacity: 1;
        }
    }
}

.multi-line-truncate {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
</style>
