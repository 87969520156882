<template>
    <div class="search-wrapper">
        <div class="search-collection">
            <div v-if="returned === 0 && term !== null">
                <h2>
                    No Results Found for your search of
                    <strong
                        ><em>{{ term }}</em></strong
                    >
                </h2>
            </div>
            <div v-if="term === null" style="text-align:center">
                <h2>
                    Looking for a sermon?
                </h2>
                <h3>You can search by Title, Topic, or Speaker</h3>
            </div>
            <div v-if="total > 0" class="search-results-found">
                <h2>
                    {{ total }} results found for your search of
                    <strong
                        ><em>{{ term }}</em>
                    </strong>
                </h2>
            </div>
            <div
                v-show="loaded"
                v-for="result in results"
                :key="result.id"
                class="search-result"
            >
                <aggregate-card :sermon="result"></aggregate-card>
            </div>
        </div>
        <div>
            <b-pagination
                v-show="loaded && pagination.total > pagination.per_page"
                align="center"
                :hide-goto-end-buttons="true"
                v-model="pagination.current_page"
                :number-of-pages="pagination.last_page"
                :per-page="pagination.per_page"
                :total-rows="pagination.total"
                v-on:change="changePage"
            ></b-pagination>
            <div class="message-wrap" v-show="!loaded">Loading Content...</div>
        </div>
    </div>
</template>

<script>
import BPagination from 'bootstrap-vue/es/components/pagination/pagination'

export default {
    components: { BPagination },
    data() {
        return {
            results: [],
            term: '',
            pagination: {},
            loaded: false,
            returned: 0,
            total: 0
        }
    },
    created() {
        if (window.results) {
            this.term = window.results.term
            this.total = window.results.total
            this.processResponse(window.results.results)
        }
    },
    mounted() {},
    methods: {
        processResponse(data) {
            this.results = data.data
            this.returned = data.data.length
            this.pagination = {
                current_page: data.current_page,
                first_page_url: data.first_page_url,
                from: data.from,
                last_page: data.last_page,
                last_page_url: data.last_page_url,
                next_page_url: data.next_page_url,
                path: data.path,
                per_page: parseInt(data.per_page, 10),
                to: data.to,
                total: parseInt(data.total, 10)
            }
            this.loaded = true
        },
        changePage(page) {
            event.preventDefault
            this.loaded = false
            this.paginateResults(page)
        },
        paginateResults(page = 1) {
            window.axios
                .get(`/search?page=${page}&q=${this.term}`)
                .then(response => {
                    this.processResponse(response.data)
                })
                .catch(() => {
                    // We returned 0 results from the filters
                    this.loaded = true
                    this.results = []
                    this.pagination = {}
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.search-collection {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    align-items: stretch;
    padding: 58px 56px 33px 56px;
    .search-results-found {
        width: 100%;
        text-align: center;
        margin-bottom: 35px;
    }
    .search-result {
        margin: 15px;
    }
}
.message-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}
</style>
