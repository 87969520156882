<template>
    <div class="sermon-player">
        <div class="video-js__video-embed"
            v-if="src.indexOf('youtube.com') === -1 && src.indexOf('vimeo.com') === -1">
            <iframe
                :src="src"
                allowfullscreen
                allowtransparency
                allow="autoplay"
            ></iframe>
        </div>

        <!-- Youtube/Vimeo video -->
        <video 
            v-if="src.indexOf('vimeo.com') > -1 || src.indexOf('youtube.com') > -1" 
            download preload="none"
            :src="src" controls playsinline :ref="src" 
            class="video-js vjs-fluid vjs-theme-fantasy"
            data-setup='{ "playbackRates": [0.5, 0.75, 1, 1.5, 1.75, 2] }'
        >
        </video>

    </div>
</template>

<script>
import { trackPlayerEventsMixin } from '../../mixins/trackPlayerEventsMixin'
export default {
    mixins: [trackPlayerEventsMixin],
    name: 'Embed',
    props: ['src'],
    mounted() {
        this.registerEvents()
    },
    watch: {
        src: function() {
            this.registerEvents()
        }
    }
}
</script>

<style lang="scss" scoped>
iframe {
    width: 100%;
    min-height: 481px;
}
</style>
