<template>
    <div
        v-if="this.sermon.interactions"
        class="heart-wrap"
    >
        <div v-if="displayOnly" class="likes">
            <span v-text="currentLikes"></span>
            <simple-svg
                :filepath="this.getSvgFilePath('heart')"
                width="30px"
                height="26.23px"
                fill="currentColor"
            ></simple-svg>
        </div>
        <a v-else class="likes" href="" @click.prevent="processInteraction()">
            <span v-if="currentLikes" v-text="currentLikes"></span>
            <simple-svg
                :filepath="this.getSvgFilePath('heart')"
                width="30px"
                height="26.23px"
                fill="currentColor"
            ></simple-svg>
        </a>
    </div>
</template>

<script>
export default {
    props: ['sermon', 'displayOnly'],

    data() {
        return {
            likes: null
        }
    },

    methods: {
        processInteraction() {
            let interactionData = new FormData()
            interactionData.append('type', 'like')
            interactionData.append('sermon_id', this.sermon.id)

            navigator.sendBeacon(
                `//api.${window.base_domain}/logger/interaction`,
                interactionData
            )
            if (
                this.supportsLocalStorage() &&
                typeof this.sermon.interactions.likes === 'number'
            ) {
                if (
                    localStorage.getItem(`liked_sermon_${this.sermon.id}`) ==
                    null
                ) {
                    this.likes = this.sermon.interactions.likes + 1
                    localStorage.setItem(
                        `liked_sermon_${this.sermon.id}`,
                        this.sermon.id
                    )
                }
            }
        },
        supportsLocalStorage: function() {
            return (
                !!window.localStorage &&
                typeof localStorage.getItem === 'function' &&
                typeof localStorage.setItem === 'function' &&
                typeof localStorage.removeItem === 'function'
            )
        }
    },
    computed: {
        currentLikes: function() {
            if (this.likes) {
                return this.likes
            }

            return this.sermon.interactions.likes
        }
    }
}
</script>

<style lang="scss" scoped>
.heart-wrap {
    display: flex;
    margin-left: auto;
}
.grid {
    .likes {
        margin-right: 15px;
    }
}
.dark {
    .likes {
        color: rgba(255, 255, 255, 1);
        span {
            color: rgba(59, 62, 68, 1);
        }
    }
}
a.likes:hover {
    color: rgba(51, 153, 255, 1);
    span {
        color: rgba(255, 255, 255, 1);
    }
}
.likes {
    margin-right: 10px;
    color: rgba(59, 62, 68, 1);
    span {
        position: absolute;
        margin-top: 5px;
        text-align: center;
        width: 30px;
        font-size: 10px;
        color: rgba(255, 255, 255, 1);
    }
}
</style>
