export const getQueryParamMixin = {
    methods: {
        getQueryParam: function (variable) {
            var query = window.location.search.substring(1)
            var vars = query.split('&')
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=')
                if (pair[0] == variable) {
                    var ret = false
                    if (pair[1].indexOf(',') > -1) {
                        ret = pair[1].split(',')
                    } else {
                        ret = [pair[1]]
                    }
                    return ret
                }
            }
            return false
        }
    }
}
