<template>
    <div>
        <a id="download" @click="openPopover" :class="{ active: active }">
            <simple-svg
                :filepath="this.getSvgFilePath('download')"
                width="20px"
                height="20px"
            ></simple-svg>
        </a>
        <b-popover target="download" placement="bottomleft">
            <template slot="title">
                <div class="d-flex align-items-end m-2">
                    <simple-svg
                        :filepath="this.getSvgFilePath('download')"
                        width="20px"
                        height="20px"
                        fill="rgba(51, 153, 255, .3)"
                    ></simple-svg>
                    <span class="mx-2">Download</span>
                </div>
            </template>
            <p>Thank you for downloading!</p>
            <div
                class="d-flex justify-content-around"
                style="min-width: 160px;"
            >
                <a
                    v-if="sermon.media.video"
                    :href="sermon.media.video"
                    :download="`${sermon.slug}-video`"
                    role="button"
                    target="_blank"
                    class="btn btn-sermon-cloud m-2"
                    >Video</a
                >
                <a
                    v-if="sermon.media.audio"
                    :href="sermon.media.audio"
                    :download="`${sermon.slug}-audio`"
                    role="button"
                    target="_blank"
                    class="btn btn-sermon-cloud m-2"
                    >Audio</a
                >
                <a
                    v-if="sermon.media.notes"
                    :href="sermon.media.notes"
                    :download="`${sermon.slug}-notes`"
                    role="button"
                    target="_blank"
                    class="btn btn-sermon-cloud m-2"
                    >Notes</a
                >
            </div>
        </b-popover>
    </div>
</template>
<script>
import BPopover from 'bootstrap-vue/es/components/popover/popover'

export default {
    components: { BPopover },
    props: ['sermon'],
    data() {
        return {
            active: false
        }
    },
    methods: {
        openPopover: function() {
            this.active = !this.active
            this.$root.$emit('bv::hide::popover')

            if (this.active) {
                this.$root.$emit('bv::show::popover', 'download')
            }
        }
    },
    watch: {
        sermon: function() {
            this.active = false
        }
    },
    mounted() {
        this.$root.$on('bv::popover::hide', bvEventObj => {
            if (bvEventObj.target.id == 'download') {
                this.active = false
            }
        })
    }
}
</script>
