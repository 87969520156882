<template>
    <div class="sermon-player">
        <div >
            <img v-if="poster" @load="posterLoaded" :src="poster" alt="Sermon Image Thumbnail" />
            <audio
                :autoplay="autoplay === 'muted' ? true : autoplay"
                :ref="src"
                controls
                playsinline
                preload="none"
                :live="live"
                :streamingid="streamingid"
                :streamingtitle="streamingtitle"
                :siteid="siteid"
                class="video-js vjs-fluid vjs-theme-fantasy"
                data-setup='{ "playbackRates": [0.5, 0.75, 1, 1.5, 1.75, 2] }'
            >
                <source :src="src" :type="type" />
            </audio>
        </div>
    </div>
</template>

<script>
import { trackPlayerEventsMixin } from '../../mixins/trackPlayerEventsMixin'
export default {
    mixins: [trackPlayerEventsMixin],
    name: 'Audio',
    props: [
        'autoplay',
        'poster',
        'src',
        'live',
        'streamingid',
        'streamingtitle',
        'siteid'
    ],
    data() {
        return {
            type: this.getType()
        }
    },
    mounted() {
        this.registerEvents()
    },
    watch: {
        src() {
            this.registerEvents()
            this.type = this.getType();
        }
    },
    methods: {
        /**
         * Determine the type of audio file that is being played
         *
         * @return {string}
         */
        getType() {
            var type = 'audio/mp3';

            if (this.src.indexOf('.m4a') != -1) {
              type = 'audio/mp4';
            } else if (this.src.indexOf('.m3u8') != -1) {
              type = 'application/x-mpegURL';
            }

            return type;
        },
        posterLoaded() {
            if (typeof window.sermonCloudResizeHeight == 'function') {
                window.sermonCloudResizeHeight()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.sermon-player {
    background-color: rgba(200, 200, 200, 1);
}
img {
    display: block;
    margin: 0 auto;
    width: 100%;
}
</style>
