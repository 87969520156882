<template>
    <div class="playlist-wrap">
        <sc-player
            v-if="selectedSermon !== null"
            :sermon="selectedSermon"
            :windowWidth="windowWidth"
        ></sc-player>
        <sc-filters
            v-if="selectedSermon !== null"
            v-on:filtering-requested="filterSermons"
            ref="filtering"
        ></sc-filters>
        <div v-if="selectedSermon !== null" class="playlist-view-selector-wrap">
            <button @click="updatePlaylistView('list-view')" class="list">
                <simple-svg
                    :filepath="this.getSvgFilePath('view-list')"
                    height="48px"
                    width="48px"
                    :class="playlistView == 'list-view' ? 'active' : 'inactive'"
                ></simple-svg>
            </button>
            <button @click="updatePlaylistView('grid-view')" class="grid">
                <simple-svg
                    :filepath="this.getSvgFilePath('view-tile')"
                    height="48px"
                    width="48px"
                    :class="playlistView == 'grid-view' ? 'active' : 'inactive'"
                ></simple-svg>
            </button>
        </div>
        <div :class="playlistView" v-show="loaded">
            <sc-playlist-item
                v-for="sermon in sermons"
                :key="sermon.slug"
                :sermon="sermon"
                v-on:sermon-selected="updatePlayer"
                v-on:category-selected="categoryFilterSelected"
                :playable="true"
                :dark="false"
                :grid="playlistView === 'grid-view'"
                :windowWidth="windowWidth"
            ></sc-playlist-item>
        </div>
        <b-pagination
            v-show="loaded && pagination.total > pagination.per_page"
            align="center"
            :hide-goto-end-buttons="true"
            v-model="pagination.current_page"
            :number-of-pages="pagination.last_page"
            :per-page="pagination.per_page"
            :total-rows="pagination.total"
            v-on:change="changePage"
        ></b-pagination>
        <div class="message-wrap" v-show="!loaded && selectedSermon !== null">Loading Content...</div>
        <div class="message-wrap" v-show="selectedSermon === null">We're sorry but there don't seem to be any sermons currently available.</div>
        <div class="message-wrap" v-show="loaded && sermons.length == 0">
            No sermons found... Try a different set of filters
        </div>
    </div>
</template>

<script>
import BPagination from 'bootstrap-vue/es/components/pagination/pagination'
import axiosCancel from 'axios-cancel'

axiosCancel(window.axios, {
    debug: false
})

export default {
    components: { BPagination },
    props: ['embedded'],
    data() {
        return {
            sermons: [],
            pagination: {},
            selectedSermon: null,
            loaded: false,
            playlistView: `${window.Church.settings.display_layout}-view`,
            windowWidth: window.innerWidth
        }
    },
    created() {
        if (window.initial_sermons) {
            this.selectedSermon = window.initial_sermons.data[0]
            this.processResponse(window.initial_sermons)
        } else {
            let howmany = this.getQueryParam('howmany')
                ? this.getQueryParam('howmany')
                : 12
            window.axios
                .get(
                    `//api.${window.base_domain}/${window.Church.slug}/sermons?howmany=${howmany}`
                )
                .then(response => {
                    this.processResponse(response.data)
                    this.selectedSermon = this.sermons[0]
                })
                .catch(error => {
                    console.log(`There was an error: ${error.response}`)
                })
        }
        navigator.sendBeacon(
            `//api.${window.base_domain}/logger/check-for-cookie`
        )
    },
    mounted() {
        window.onresize = () => {
            this.windowWidth = window.innerWidth
            if (this.windowWidth < 768) {
                this.playlistView = 'grid-view'
            }
        }
    },
    methods: {
        updatePlaylistView(view) {
            this.playlistView = view
            if (this.embedded) {
                window.sermonCloudResizeHeight()
            }
        },
        processResponse(sermons) {
            this.sermons = sermons.data
            this.pagination = {
                current_page: sermons.current_page,
                first_page_url: sermons.first_page_url,
                from: sermons.from,
                last_page: sermons.last_page,
                last_page_url: sermons.last_page_url,
                next_page_url: sermons.next_page_url,
                path: sermons.path,
                per_page: parseInt(sermons.per_page, 10),
                to: sermons.to,
                total: parseInt(sermons.total, 10)
            }
            this.loaded = true
            if (this.embedded) {
                window.sermonCloudResizeHeight()
            }
        },
        updatePlayer(sermon) {
            this.selectedSermon = sermon
            window.parent.postMessage(['scrollToTop'], '*')
            this.$refs.filtering.expanded = false
        },
        categoryFilterSelected(category) {
            this.$refs.filtering.filters.series = []
            this.$refs.filtering.filters.speakers = []
            this.$refs.filtering.filters.passages = []
            this.$refs.filtering.filters.categories = [category]
        },
        changePage(page) {
            event.preventDefault
            this.loaded = false
            window.parent.postMessage(['scrollToTop'], '*')
            this.filterSermons(
                this.$refs.filtering.filters,
                page,
                this.playlistView
            )
        },
        filterSermons(filters, page = 1, playlistView = 'list-view', scrollTop= true) {
            if (this.windowWidth < 768) {
                playlistView = 'grid-view'
            }
            let filterQuery = Object.keys(filters)
                .filter(key => filters[key].length > 0)
                .map(key => `filters[${key}]=${filters[key]}`)
                .join('&')
            this.loaded = false
            if (scrollTop) {
                window.parent.postMessage(['scrollToTop'], '*')
            }
            let howmany = this.getQueryParam('howmany')
                ? this.getQueryParam('howmany')
                : 12
            window.axios
                .get(
                    `//api.${window.base_domain}/${
                        window.Church.slug
                    }/sermons?page=${page}&${filterQuery}&howmany=${howmany}`,
                    {
                        requestId: 'filtering_request'
                    }
                )
                .then(response => {
                    this.playlistView = playlistView
                    this.processResponse(response.data)
                })
                .catch(error => {
                    if (window.axios.isCancel(error)) {
                        // If any filter requests are pending we
                        // cancel them here to keep responses fast
                        return
                    }
                    // We returned 0 sermons from the filters
                    this.loaded = true
                    this.sermons = []
                    this.pagination = {}
                    if (this.embedded) {
                        window.sermonCloudResizeHeight()
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss';
.playlist-wrap {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    .playlist-view-selector-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button {
            display: none;
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            &.list {
                margin-right: 5px;
            }
            &.grid {
                margin-right: 10px;
            }
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
    }
}
.message-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}
.grid-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
</style>
