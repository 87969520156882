<template>
    <div class="buttons-wrap" :class="{ active: active }">
        <sc-more-info-download
            v-if="active"
            :sermon="sermon"
        ></sc-more-info-download>
        <sc-more-info-share v-if="active" :sermon="sermon"></sc-more-info-share>
        <a
            @click="active = !active"
            class="more-info-dots"
            :class="{ active: active }"
        >
            <simple-svg
                :filepath="this.getSvgFilePath('dots-horizontal-triple')"
                width="21px"
                height="21px"
                @click="active = !active"
            ></simple-svg>
        </a>
    </div>
</template>
<script>
export default {
    props: ['sermon'],
    data() {
        return {
            active: false
        }
    },
    watch: {
        sermon: function() {
            this.active = false
        }
    }
}
</script>
<style lang="scss" scoped>
.buttons-wrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    width: 100px;
    margin-right: 15px;
    &.active {
        flex-direction: row;
    }
}
</style>
