<template>
    <div class="aggregate-collection-wrap" :class="{ special: special }">
        <h3>{{ title }}</h3>
        <div class="aggregate-collection">
            <div
                class="aggregate-card-wrap"
                v-for="sermon in sermons"
                :key="sermon.id"
            >
                <aggregate-card
                    :dark="special"
                    :sermon="sermon"
                ></aggregate-card>
            </div>
        </div>
    </div>
</template>

<script>
let Flickity = require('flickity')

export default {
    props: ['special', 'title', 'data'],
    data() {
        return {
            sermons: JSON.parse(this.data)
        }
    },
    methods: {
        getNextLabel() {
            return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" style="width: 44px; height: 44px; fill="currentColor"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/ ></svg>`
        },
        getPreviousLabel() {
            return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" style="width: 44px; height: 44px; fill="currentColor"><path d="M7.05 9.293L6.343 10 12 15.657l1.414-1.414L9.172 10l4.242-4.243L12 4.343z"/></svg>`
        }
    },
    mounted() {
        document
            .querySelectorAll('.aggregate-collection-wrap')
            .forEach(function(carousel) {
                new Flickity(carousel, {
                    cellSelector: '.aggregate-card',
                    groupCells: true,
                    wrapAround: true,
                    pageDots: false
                })
            })
    }
}
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss';

.aggregate-collection-wrap {
    padding: 58px 56px 33px 56px;
    color: rgba(59, 62, 68, 1);
    h3 {
        color: rgba(31, 33, 38, 1);
        font-size: 28px;
        line-height: 34px;
    }
    .flickity-button {
        background: transparent;
        color: rgba(31, 33, 38, 1);
        &:hover {
            background: transparent;
        }
    }
    &.special {
        padding-top: 43px;
        background-color: rgb(65, 75, 100);
        color: #ffffff;
        h3 {
            color: #ffffff;
            font-weight: 700;
        }
        .flickity-button {
            color: #ffffff;
        }
    }
    .aggregate-card {
        margin-right: 41px;

        @include media-breakpoint-down(sm) {
            margin-right: 6px;
            padding: 0;
            top: -6px;
            transform: scale(0.9);
            transition: top 0.3s ease-in-out, transform 0.3s ease-in-out;
            width: 250px;

            &.is-selected {
                top: 0;
                transform: scale(1);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;

        > h3 {
            padding: 0 56px;
            text-align: center;
        }

        .flickity-button {
            display: none;
        }
    }
}
</style>
