<template>
    <div class="filter-wrap" :class="{ active: expanded }">
        <div class="filter-bar">
            <a
                @click="expanded = !expanded"
                class="d-flex align-items-center"
                :class="{ active: expanded }"
            >
                <h3 class="mr-2">Filters</h3>
                <svg xmlns="http://www.w3.org/2000/svg" class="filter-bar-arrow-container" style="width: 20px; height: 20px; fill: currentColor;">
                    <defs>
                        <path id="a" d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/>
                        <path id="d" d="M0 0h24v24H0z"/>
                        <filter id="c" width="225%" height="225%" x="-63%" y="-63%" filterUnits="objectBoundingBox">
                            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5"/>
                            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0"/>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd" transform="translate(-3 -6)">
                        <path d="M0 0h24v24H0z"/>
                        <mask id="b" fill="#fff">
                            <use xlink:href="#a"/>
                        </mask>
                        <g mask="url(#b)" class="filter-bar-arrow-to-rotate">
                            <use fill="#000" filter="url(#c)" xlink:href="#d"/>
                            <use fill="currentColor" xlink:href="#d"/>
                        </g>
                    </g>
                </svg>
            </a>
            <div class="filter-badges-wrap d-none d-md-flex">
                <template v-for="(filter, filterName) in filters">
                    <div class="badge badge-tag dismissible" v-for="(singleFilter) in filter">
                        <span class="name">{{
                            getNameBySlugAndType(filterName, singleFilter)
                        }}</span>
                        <button
                            @click="dismissFilter(filterName, singleFilter)"
                            type="button"
                            class="dismiss"
                            aria-label="Remove Filter"
                        >
                            <simple-svg
                                :filepath="getSvgPath('close')"
                                width="12px"
                                height="12px"
                                fill="currentColor"
                            ></simple-svg>
                            <span aria-hidden="true" class="sr-only">Remove</span>
                        </button>
                    </div>
                </template>
            </div>
            <!-- on small screens move the filters toggle left if there is a done / clear / close link -->
            <div class="d-md-none filter-bar-clear-selections" :class="{ 'justify': expanded ||
                                                                                    filters.series.length ||
                                                                                    filters.speakers.length ||
                                                                                    filters.passages.length ||
                                                                                    filters.categories.length }">
                <!-- if there are filters selected -->
                <template v-if="filters.series.length ||
                                filters.speakers.length ||
                                filters.passages.length ||
                                filters.categories.length">
                    <!-- if the filters are expanded and at least 1 is selected -->
                    <a v-if="expanded" @click="expanded = !expanded">
                        done
                    </a>
                    <!-- if the filters are collapsed and at least 1 is selected -->
                    <a v-else @click="clearAllFilters()">
                        clear selections
                    </a>
                </template>
                <!-- if the filters are expanded and none are selected -->
                <template v-else-if="expanded">
                    <a @click="expanded = !expanded">
                        close
                    </a>
                </template>
            </div>
        </div>
        <div class="filters" v-show="expanded">
            <div class="table-wrap">
                <table class="table table-borderless">
                    <thead>
                        <tr class="d-none d-md-table-row">
                            <th><h2>Series</h2></th>
                            <th><h2>Speaker</h2></th>
                            <th><h2>Passage</h2></th>
                            <th><h2>Category</h2></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="d-none d-md-table-row">
                            <td><br /></td>
                        </tr>
                        <tr>
                            <td v-bind:class="{'divider' : index !== Object.keys(checkboxGroups).length - 1}"
                                v-for="(checkboxGroup, key, index) in checkboxGroups">
                                <h2 class="d-md-none">{{ key.toString().charAt(0).toUpperCase() + key.slice(1) }}</h2>
                                <b-form-group>
                                    <b-form-checkbox
                                        v-on:change="selectAllFor(key)"
                                        v-model="allSelected[key]"
                                    >
                                        All
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        v-for="checkbox in checkboxGroup.slice(0, 4)"
                                        :key="checkbox.key"
                                        v-model="filters[key]"
                                        :value="checkbox.slug"
                                        :name="checkbox.slug"
                                    >
                                        {{ checkbox.title }}
                                    </b-form-checkbox>
                                    <!-- group checkboxes 5+ so we can hide them on small screens -->
                                    <div v-if="checkboxGroup.length > 4">
                                        <div class="collapse-filter-group collapse" :id="'collapse-filter-group-' + key">
                                            <b-form-checkbox
                                                v-for="checkbox in checkboxGroup.slice(4)"
                                                :key="checkbox.key"
                                                v-model="filters[key]"
                                                :value="checkbox.slug"
                                                :name="checkbox.slug"
                                            >
                                                {{ checkbox.title }}
                                            </b-form-checkbox>
                                        </div>
                                        <a class="d-md-none filter-group-toggle-container"
                                           @click="toggleFilterGroupContainer($event, 'collapse-filter-group-' + key)"
                                           href="#"
                                           role="button"
                                           aria-expanded="false"
                                           :aria-controls="'collapse' + key">
                                            <span class="expand-filter-group-toggle">
                                                <simple-svg
                                                    :filepath="getSvgFilePath('chevron-down')"
                                                    width="20px"
                                                    height="20px"
                                                    fill="currentColor"
                                                    class="toggle-icon"
                                                ></simple-svg>More
                                            </span>
                                            <span class="collapse-filter-group-toggle">
                                                <simple-svg
                                                    :filepath="getSvgFilePath('chevron-up')"
                                                    width="20px"
                                                    height="20px"
                                                    fill="currentColor"
                                                    class="toggle-icon"
                                                ></simple-svg>Fewer
                                            </span>
                                        </a>
                                    </div>
                                    <b-form-checkbox
                                        v-for="checkbox in checkboxGroup"
                                        :key="checkbox.slug"
                                        :value="checkbox.slug"
                                        :name="checkbox.slug"
                                        class="visually-hidden-bold"
                                    >
                                        {{ checkbox.title }}
                                    </b-form-checkbox>
                                </b-form-group>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import BFormGroup from 'bootstrap-vue/es/components/form-group/form-group'
import BFormCheckbox from 'bootstrap-vue/es/components/form-checkbox/form-checkbox'

export default {
    components: { BFormGroup, BFormCheckbox },

    data() {
        return {
            expanded: false,
            checkboxGroups: {
                series: [],
                speakers: [],
                passages: [],
                categories: [],
            },
            filters: {
                series: [],
                speakers: [],
                passages: [],
                categories: []
            },
            allSelected: {
                series: false,
                speakers: false,
                passages: false,
                categories: false
            },
            firstLoad : true
        }
    },
    created() {
        Promise.all([
           this.getFilterFor('series'),
           this.getFilterFor('speakers'),
           this.getFilterFor('passages'),
           this.getFilterFor('categories'),
        ]).then(() => {
            this.firstLoad = false;
        })
    },
    methods: {
        toggleFilterGroupContainer(e, key) {
            e.preventDefault();
            document.getElementById(key).classList.toggle('show');
        },
        getFilterFor(type) {
            return window.axios
                .get(
                    `//api.${window.base_domain}/${
                        window.Church.slug
                    }/filtering/${type}`
                )
                .then(response => {
                    this.checkboxGroups[type] = response.data.data
                    let paramSelections = this.getQueryParam(type)
                    if (paramSelections) {
                        this.filters[type] = paramSelections
                    }
                })
                .catch(error => {
                    console.log(
                        `There was an error: ${error.response.data.message}`
                    )
                })
        },
        selectAllFor(type) {
            var selected = [];
            this.checkboxGroups[type].forEach(function(item) {
                selected.push(item.slug)
            });
            this.filters[type] = selected;
            this.allSelected[type] = true;
        },
        filteringRequested() {
            this.$emit('filtering-requested', this.filters, 1, 'list-view', !this.firstLoad)
        },
        dismissFilter(type, filter) {
            this.filters[type] = this.filters[type].filter(function(value) {
                return value !== filter
            })
        },
        getSvgPath(path) {
         return this.getSvgFilePath(path)
        },
        getNameBySlugAndType(type, slug) {
            return this.checkboxGroups[type].filter(function(object) {
                return object.slug === slug
            })[0].title
        },
        clearAllFilters() {
            var filters = {};
            Object.keys(this.filters).forEach(function(filter) {
                filters[filter] = []
            });
            this.filters = filters;
        }
    },
    watch: {
        allSelected: {
            handler() {
                var self = this;
                Object.keys(self.allSelected).forEach(function(type) {
                    if (!self.allSelected[type]) {
                        if (self.filters[type].length === self.checkboxGroups[type].length) {
                            self.filters[type] = []
                        }
                    }
                });
            },
            deep: true
        },
        filters: {
            handler() {
                var self = this
                Object.keys(self.filters).forEach(function(type) {
                    if (self.filters[type].length !== self.checkboxGroups[type].length) {
                        self.allSelected[type] = false
                    } else {
                        self.allSelected[type] = true
                    }
                });
                self.filteringRequested()
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss';

.filter-wrap {
    min-height: 64px;
    background-color: rgba(242, 242, 243, 1);
    margin-bottom: 25px;
    &.active {
        margin-bottom: 46px;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
    }
}
.filter-bar {
    display: flex;
    align-items: center;
    min-height: 64px;
    margin-left: 51px;
    a {
        color: rgba(79, 79, 79, 1);
        cursor: pointer;
        &:hover {
            color: var(--blue);
        }

        .filter-bar-arrow-container {
            margin-top: 6px;
        }

        .filter-bar-arrow-to-rotate {
            transform: rotate(0);
            transform-origin: 12px 12px;
            transition: transform 0.3s;
        }

        &.active {
            .filter-bar-arrow-to-rotate {
                transform: rotate(180deg);
            }
        }
    }
    h3 {
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 20px;
    }
    .filter-badges-wrap {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;
    }
}
.filters {
    .table-wrap {
        margin: 16px 21px 0 21px;
        table {
            h2 {
                color: rgba(79, 79, 79, 1);
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0.56px;
                line-height: 22px;
            }
        }
        thead {
            border-bottom: 3px solid rgba(59, 62, 68, 0.25);
        }

        .divider {
            border-right: 2px solid rgba(168, 168, 168, 0.21);
            margin-left: 10px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .filter-bar {
        justify-content: center;
        margin: 0 33px;

        a {
            color: var(--blue);
            margin: 0;
        }

        // on small screens filter badges are replaced by a "clear selection" link
        .filter-badges-wrap .badge {
            display: none;
        }

        .filter-bar-clear-selections {
            flex-grow: 0;
            font-size: 12px;
            opacity: 0;
            text-align: right;
            transition: flex-grow 0.5s, opacity 0.2s 0.3s;

            &.justify {
                flex-grow: 1;
                opacity: 1;
            }
        }
    }

    // on small screens make filters display as rows instead of columns
    .filters .table-wrap td {
        display: block;

        &.divider {
            border: none;
            margin: 0;
        }

        h2 {
            border-bottom: 3px solid rgba(59, 62, 68, 0.25);
            margin-bottom: 12px;
            padding-bottom: 12px;
        }
    }

    // show more / show fewer checkboxes in Series, Speakers, Passages, Categories
    .filter-group-toggle-container {
        display: block;
        text-align: center;

        .toggle-icon {
            display: inline;
        }
    }

    .collapse-filter-group {
        // when filter group is collapsed only show the expand button
        &.collapse + .filter-group-toggle-container {
            color: #353C43;

            .expand-filter-group-toggle {
                display: block;
            }

            .collapse-filter-group-toggle {
                display: none;
            }
        }

        // when filter group is expanded only show the collapse button
        &.collapse.show + .filter-group-toggle-container {
            .expand-filter-group-toggle {
                display: none;
            }

            .collapse-filter-group-toggle {
                display: block;
            }
        }

        // when animating don't show either expand or collapse buttons, but keep their spacing
        &.collapsing + .filter-group-toggle-container {
            .expand-filter-group-toggle,
            .collapse-filter-group-toggle {
                visibility: hidden;
            }
        }
    }
}

// Ensure all checkboxes display on desktop devices, there is no collapsing
@include media-breakpoint-up(md) {
    .collapse {
        display: block;
    }
}
</style>
