<template>
    <header>
        <nav class="navbar navbar-expand-md">
            <a href="/" class="navbar-brand"><simple-svg :filepath="this.getSvgFilePath('logo', '/')" height="78px" fill="#ffffff"></simple-svg></a>
            <div>
                <button class="navbar-toggler collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#sermon-cloud-homepage-navigation"
                        aria-controls="sermon-cloud-homepage-navigation"
                        aria-expanded="false" aria-label="'Toggle navigation">
                    <span class="navbar-toggler-icon" :class="{ 'navbar-toggler-icon-close': !collapsed }">
                        <simple-svg v-if="!collapsed" :filepath="this.getSvgFilePath('close')" height="17px" stroke="#ffffff" fill="#ffffff"></simple-svg>
                    </span>
                </button>
            </div>
            <div class="navbar-collapse collapse" id="sermon-cloud-homepage-navigation">
<!--                <div class="nav-search">-->
<!--                    <form action="/search" method="get">-->
<!--                        <fieldset>-->
<!--                            <input class="nav-search-input" type="text" name="q" value="" placeholder="Search" autocomplete="off"-->
<!--                            /><input class="nav-search-submit" type="submit" value="SEARCH" />-->
<!--                        </fieldset>-->
<!--                    </form>-->
<!--                </div>-->
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a href="http://hello.sermoncloud.com/join" class="nav-link">Add Your Church</a>
                    </li>
                    <li class="nav-item">
                        <a href="http://hello.sermoncloud.com" class="nav-link">About Sermon Cloud</a>
                    </li>
                    <li class="nav-item">
                        <a href="https://my.sermoncloud.com/" class="nav-link">Log In</a>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
    export default {
        data() {
            return {
                collapsed: true
            }
        },
        mounted() {
            var _self = this;
            $('.navbar-collapse').on('hide.bs.collapse', function () {
                _self.collapsed = true;
                $('header').removeClass('expanded');
            }).on('shown.bs.collapse', function() {
                _self.collapsed = false;
                $('header').addClass('expanded');
            });
        },
    }
</script>

<style lang="scss">
    header .navbar-brand .simple-svg {
        max-width: 100%;
    }
</style>

<style lang="scss" scoped>
    @import 'node_modules/bootstrap/scss/_functions.scss';
    @import 'node_modules/bootstrap/scss/_variables.scss';
    @import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss';

    /* Header styles for all device sizes */
    header {
        background-color: rgba(25, 39, 68, 1);
        min-height: 110px;
        transition: background-color 0.2s ease-in-out;
        width: 100%;

        .navbar-brand {
            max-width: 33%;
            z-index: 1;

            .simple-svg {
                max-width: 100%;
            }
        }

        nav {
            min-height: 110px;

            /* Open / Close navigation icon styles */
            .navbar-toggler {
                bottom: 6px;
                position: absolute;
                right: 0;

                .navbar-toggler-icon {
                    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
                }

                .navbar-toggler-icon-close {
                    background-image: none;
                    line-height: 25px;
                }
            }

            /* Header links section */
            #sermon-cloud-homepage-navigation {
                padding-right: 35px;

                /* Each link in the navigation */
                .nav-link {
                    color: rgba(255, 255, 255, 1);
                    font-family: Montserrat,serif;
                    font-size: 16px;
                    margin-left: 40px;
                    white-space: nowrap;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }


        /* Header styles specific to desktops and large browsers */
        @include media-breakpoint-up(md) {
            background-color: rgba(25, 39, 68, 0.82);
            height: 100px;
            position: absolute;

            .navbar-nav {
                /* Make the 1st nav link look like a button */
                > li:first-of-type > .nav-link {
                    background: #4b91e3;
                    border-radius: 50px;
                    opacity: 1;
                    padding: 0 40px;

                    &:hover {
                        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.25);
                    }
                }

                /* Each link in the navigation */
                .nav-link {
                    line-height: 50px;
                    padding: 0;
                }
            }
        }

        /* Header styles specific to mobile devices and small browsers */
        @include media-breakpoint-down(sm) {
            min-height: 0;

            /* Styles applied when mobile navigation is shown */
            &.expanded {
                background-color: #3c3e44;
            }

            nav {
                min-height: 0;

                &.navbar {
                    padding: 0;
                }

                /* Sermon cloud logo */
                .navbar-brand {
                    height: 66px;
                    margin: 0 auto;
                    min-width: 160px;
                    padding-top: 2px;
                    width: 160px;
                }

                /* Expandable header links section */
                #sermon-cloud-homepage-navigation {
                    background: #3c3e44;
                    height: 100vh;
                    left: 100%;
                    padding: 0;
                    position: absolute;
                    top: 66px;
                    transition: left 0.2s ease-in-out;
                    width: 100vw;
                    z-index: 100;

                    /* Animate the links from left (out of view) to right */
                    &.collapse.show {
                        left: 0;
                    }

                    /* List of navigation links */
                    .navbar-nav {
                        margin: auto;
                        width: 80%;

                        /* Each link in the navigation */
                        .nav-link {
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 30px;
                            margin-left: 0;
                            padding: .6rem 0;
                        }
                    }

                    /* Mobile search bar inside the navigation pop-out */
                    .nav-search {
                        display: block;
                        margin: 20px auto;
                        width: 80%;

                        /* Styles common to the search box and button */
                        input {
                            border: 1px solid #ffffff;
                            line-height: 35px;
                            padding: 0 10px;
                        }

                        /* Search box */
                        .nav-search-input {
                            background: #ffffff;
                            width: 70%;
                        }

                        /* Search button */
                        .nav-search-submit {
                            background: #3c3e44;
                            color: #ffffff;
                            font-size: 13px;
                            left: -1px;
                            position: relative;
                            width: 30%;
                        }
                    }
                }
            }
        }
    }
</style>
