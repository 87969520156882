<template>
    <div class="aggregate-card">
        <a
            class="image"
            :class="{ search: search }"
            :style="sermonImage"
            @click="$emit('sermon-selected', sermon)"
            :href="sermonUrl"
        >
            <simple-svg
                :filepath="this.getSvgFilePath('play-outline')"
                width="44px"
                height="44px"
                fill="rgba(255, 255, 255, 1)"
            ></simple-svg>
        </a>
        <span class="title-wrap">
            <a :href="sermonUrl"><h3 v-html="sermon.title"></h3></a>
            <sc-heart
                class="grid"
                :class="{ dark: dark }"
                :sermon="sermon"
                display-only="true"
            ></sc-heart>
        </span>
        <h4 v-if="sermon.series.name" v-html="sermon.series.name"></h4>
        <div class="meta">
            <p v-if="sermon.preacher.name" v-html="sermon.preacher.name"></p>
            <p v-html="sermon.date.date"></p>
            <p v-html="sermon.church.name"></p>
        </div>
    </div>
</template>

<script>
export default {
    props: ['sermon', 'dark', 'search'],
    computed: {
        sermonImage: function() {
            let imageUrl = this.getSvgFilePath('headphones')
            let backgroundSize = '53px'

            if (this.sermon.image_url) {
                imageUrl = this.sermon.image_url
                backgroundSize = 'cover'
            }
            return `
                background-image:url('${imageUrl}');
                background-size: ${backgroundSize};
                background-position: center;
                background-repeat: no-repeat;
            `
        },
        sermonUrl: function() {
            return `//${this.sermon.church.slug}.${
                window.base_domain
            }/sermons/${this.sermon.slug}`
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss';
.aggregate-card {
    min-width: 250px;
    max-width: 350px;
    padding: 0 25px;
    .image {
        height: 142px;
        width: 250px;
        border-radius: 10px;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
        margin-bottom: 27px;
        min-width: 250px;
        min-height: 142px;
        max-width: 350px;
        max-height: 200px;
        background-color: rgba(200, 200, 200, 1);
        opacity: 0.74;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 350px;
            max-height: 200px;
        }
        &:hover {
            opacity: 1;
        }
    }
    .search {
        display: none;
        @include media-breakpoint-up(md) {
            display: flex;
            width: 300px !important;
        }
    }
    @include media-breakpoint-up(md) {
        padding: 0;
        .image {
            height: 200px;
            width: 350px;
        }
    }
    .title-wrap {
        display: flex;
        justify-content: space-between;
        a:hover {
            text-decoration: none;
        }
    }
    h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
    }
    h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }
    .meta {
        font-size: 12px;
        line-height: 15px;
        p {
            margin-bottom: 0.5rem;
        }
    }
}
</style>
