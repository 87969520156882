<template>
    <div class="livestream">
        <live-stream-and-bible-cloud-layout
            :church="church"
            :containerClass="containerClass"
            :createPrayerUrl="createPrayerUrl"
            :settings="streamSettings"
            :sidebarClass="sidebarClass"
            :site="site"
        >
            <!-- Add stream content to the main section of the page -->
            <template v-slot:content>
                    <!-- When not streaming show some offline content -->
                <template v-if="!isStreaming">
                    <sc-rotator
                        v-if="useStreamRotator"
                        :rotatorData="currentStream.offline_content.rotator"
                    ></sc-rotator>

                    <sc-video
                        v-else-if="useStreamVideoLoop"
                        autoplay="muted"
                        key="streamLoop"
                        :loop="true"
                        :src="currentStream.offline_content.video.url"
                    ></sc-video>

                    <div
                        v-else-if="useStreamImage"
                        class="livestream-image"
                    >
                        <img
                            :alt="currentStream.offline_content.image.description"
                            :src="currentStream.offline_content.image.url"
                        />
                    </div>

                     <sc-rotator
                        v-else-if="useSettingsRotator"
                        :rotatorData="streamSettings.default_offline_content.rotator"
                    ></sc-rotator>

                    <sc-video
                        v-else-if="useSettingsVideoLoop"
                        autoplay="muted"
                        key="settingsLoop"
                        :loop="true"
                        :src="streamSettings.default_offline_content.video.url"
                    ></sc-video>

                    <div
                        v-else-if="streamSettings.social_image_url"
                        class="livestream-image"
                    >
                        <img :src="streamSettings.social_image_url" />
                    </div>

                    <div
                        v-else-if="streamSettings.logo_url"
                        class="livestream-image"
                    >
                        <img :src="streamSettings.logo_url" />
                    </div>
                </template>

                <!-- When we are streaming, and there is a stream file, show it -->
                <template v-else-if="currentStream && currentStream.m3u8">
                    <sc-video
                        v-if="isStreaming"
                        autoplay="muted"
                        :src="currentStream.m3u8"
                        :live="true"
                        key="stream"
                        :poster="useStreamImage ? currentStream.offline_content.image.url : ''"
                        :streamingid="currentStream.id"
                        :streamingtitle="currentStream.title"
                        :siteid="currentStream.siteid"
                    ></sc-video>
                </template>

                <!-- Details displayed on larger browsers -->
                <div
                    v-if="currentStream"
                    class="livestream-details"
                >
                    <div class="row">
                        <div class="col">
                            <h3 v-html="currentStream.title"></h3>
                        </div>
                        <div class="col">
                            <div class="livestream-details-date">
                                {{currentStream.start.date}}
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div
                        v-if="currentStream.preacher"
                        class="font-weight-bold"
                        v-html="currentStream.preacher"
                    ></div>
                </div>
            </template>

            <!-- Add a countdown above the sidebar -->
            <template v-slot:sidebar-header>
                <sc-countdown
                    @stream-ended="handleStreamEnd"
                    @stream-started="handleStreamStart"
                    @stream-updated="updateStream"
                    :dark="dark"
                    :currentStream="currentStream"
                ></sc-countdown>
            </template>

            <!-- Add stream specific information to the details tab -->
            <template
                v-slot:details
                v-if="currentStream && currentStream.title"
            >
                <h3 v-html="currentStream.title"></h3>

                <div v-if="currentStream.stream_times">
                    {{currentStream.stream_times}}
                </div>

                <div
                    v-if="sermonPassages"
                    v-html="sermonPassages"
                ></div>

                <div v-if="currentStream.description">
                    <hr />
                    <span v-html="currentStream.description"></span>
                </div>
            </template>

        </live-stream-and-bible-cloud-layout>
    </div>
</template>

<script>
export default {
    props: {
        church: Object,
        createPrayerUrl: String,
        site: Object,
        streams: Object,
        streamSettings: Object
    },
    data() {
        return {
            currentStream: undefined,
            dark: true,
            isStreaming: false,
        }
    },
    created() {
        // Set the current stream to the 1st stream listed
        this.currentStream = this.streams ? this.streams.data[0] : null;
    },
    computed: {
        /**
         * Check that the stream rotator has content
         *
         * @returns {number}
         */
        useStreamRotator() {
            return this.currentStream &&
                   this.currentStream.offline_content.rotator &&
                   Object.keys(this.currentStream.offline_content.rotator).length;
        },

        /**
         * Check the the stream specific video loop has content
         *
         * @returns {number}
         */
        useStreamVideoLoop() {
            return this.currentStream &&
                   this.currentStream.offline_content.video &&
                   Object.keys(this.currentStream.offline_content.video).length;
        },

        /**
         * Check the the stream specific image has content
         *
         * @returns {number}
         */
        useStreamImage() {
            return this.currentStream &&
                   this.currentStream.offline_content.image &&
                   Object.keys(this.currentStream.offline_content.image).length;
        },

        /**
         * Check the default settings rotator has content
         *
         * @returns {number}
         */
        useSettingsRotator() {
            return this.streamSettings.default_offline_content.rotator &&
                   Object.keys(this.streamSettings.default_offline_content.rotator).length;
        },

        /**
         * Check the default settings video loop has content
         *
         * @returns {number}
         */
        useSettingsVideoLoop() {
            return this.streamSettings.default_offline_content.video &&
                   Object.keys(this.streamSettings.default_offline_content.video).length;
        },

        /**
         * Convert array of passages into a comma separated string
         *
         * @returns {String}
         */
        sermonPassages: function() {
            return this.currentStream.passages.join(', ');
        },


        /**
         * Set class names that control the size of the content, used to make room for the countdown
         */
        containerClass() {
            var containerClass = '';
            if (this.currentStream && !this.isStreaming) {
                containerClass = 'livestream-container-hasCurrentStream';
            }
            return containerClass;
        },

        /**
         * Set class names that control the size of the sidebar, used to make room for the countdown
         */
        sidebarClass() {
            var sidebarClass = '';
            if (this.currentStream && !this.isStreaming) {
                sidebarClass = 'livestream-sidebar-hasCurrentStream';
            }
            return sidebarClass;
        },
    },
    methods: {
        /**
         * The countdown tells us when a stream starts, then we hide offline content and show the stream video
         */
        handleStreamStart() {
            this.isStreaming = true;
        },

        /**
         * The countdown tells us when a stream ends, then we hide the old stream
         *
         * @param {Object} nextStream - the next stream to display
         */
        handleStreamEnd() {
            this.isStreaming = false;
            this.updateStream(null);
        },

        /**
         * Update the current stream with any changed information from the server
         *
         * @param {Object} stream - a stream with up to date data
         */
        updateStream(stream) {
            this.currentStream = stream;
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    $navbar-height: 76px;

    // Style offline content images when there are not rotators or video loops to display
    // Ensure they are full width and the same height as the video which will replace them
    .livestream-image {
        height: 0;
        overflow: hidden;
        padding-bottom: 56.25%;

        img {
            height: auto;
            width: 100%;
        }
    }

    // Style the countdown for the livestream layout
    ::v-deep .sermon-countdown.sermon-countdown-dark {
        background: var(--user-alternate-color);
        border-top: none;
        padding-top: 2px;
    }

    // Detail text displaying under the stream
    .livestream-details {
        padding: 15px 24px;

        .livestream-details-date {
            color: rgba(0, 0, 0, 0.4);
            text-align: right;
        }

        hr {
            border: none;
            border-top: 2px solid var(--user-alternate-color);
            display: inline-block;
            margin: 0 0 10px 0;
            width: 85px;
        }
    }

    // For screens sized "lg" and wider
    @include media-breakpoint-up(lg) {
        $countdown-height: 85px;

        // NB: Override videojs default sizing due to a bug in Chrome
        //     The padding-top method follows video-js' vjs-fluid pattern
        //     I have filed a bug with video-js and we should update this when they respond
        //     https://github.com/videojs/video.js/issues/7018
        ::v-deep {
            #rotator ul {
                height: 42.18vw !important;
            }

            .video-js.vjs-fluid {
                height: 42.18vw;
                padding: 0;
            }
        }

        // Make room for the countdown above the sidebar toggles
        ::v-deep .livestream-sidebar-hasCurrentStream {
            padding-top: $countdown-height;
        }

        // Make the countdown fit in the sidebar
        ::v-deep .sermon-countdown {
            height: $countdown-height;
            position: absolute;
            width: 100%;

            .sermon-countdown-watch-text {
                margin-bottom: 4px;
            }

            .sermon-countdown-number-container {
                font-size: 30px;
                line-height: 26px;
            }

            .sermon-countdown-text {
                font-size: 10px;
                font-weight: normal;
            }
        }
    }

    // For screens sized "md" and narrower
    @include media-breakpoint-down(md) {
        $countdown-height: 110px;

        // Make room for the countdown, which we absolutely position above the stream
        ::v-deep .livestream-container-hasCurrentStream {
            height: auto;
            margin-top: $countdown-height;
        }

        // NB: Override videojs default sizing due to a bug in Chrome
        //     The padding-top method follows video-js' vjs-fluid pattern
        //     I have filed a bug with video-js and we should update this when they respond
        //     https://github.com/videojs/video.js/issues/7018
        ::v-deep {
            .video-js.vjs-fluid {
                height: 54.55vw;
                padding: 0;
            }
        }

        // Move the countdown to the top of the page (under the navigation and above the stream)
        ::v-deep .sermon-countdown {
            height: $countdown-height;
            left: 0;
            position: absolute;
            top: $navbar-height;
            width: 100%;
        }

        // Update the display of details to work on smaller browsers
        .livestream-details {
            h3 {
                font-size: 16px;
            }

            .livestream-details-date {
                font-size: 12px;
            }
        }
    }

    // For screens sized "sm" and narrower
    @include media-breakpoint-down(sm) {
        $countdown-height: 82px;

        // The countdown gets smaller on very small browsers and is still absolutely positioned
        // so make sure the content fits together still
        ::v-deep .livestream-container-hasCurrentStream {
            margin-top: $countdown-height;
        }

        ::v-deep .sermon-countdown {
            height: $countdown-height;
        }
    }
</style>
