<template>
    <div class="sermon-player-wrap">
        <sc-video
            v-if="selectedType === 'video'"
            :poster="poster"
            :src="sermon.media.video"
        ></sc-video>
        <sc-embed
            v-if="selectedType === 'embed'"
            :src="sermon.media.video"
        ></sc-embed>
        <sc-audio
            v-if="selectedType === 'audio'"
            :src="sermon.media.audio"
            :poster="poster"
        ></sc-audio>
        <sc-playlist-item
            :sermon="sermon"
            :dark="true"
            :playable="false"
            :full="true"
            :startExpanded="false"
            :windowWidth="getWindowWidth"
            :selectedType="selectedType"
            v-on:media-selected="updateType"
        ></sc-playlist-item>
    </div>
</template>

<script>
export default {
    props: ['sermon', 'windowWidth'],
    data() {
        return {
            selectedType: ''
        }
    },
    watch: {
        sermon: function() {
            this.determineInitialType()
        }
    },
    created() {
        this.determineInitialType()
    },
    methods: {
        updateType(type) {
            if (type == 'video') {
                if (this.sermon.media.embed) {
                    this.selectedType = 'embed'
                    return null
                }
                if (this.sermon.media.video) {
                    this.selectedType = 'video'
                    return null
                }
            }
            this.selectedType = type
        },
        determineInitialType() {
            this.selectedType = undefined

            this.$nextTick(() => {
                if (this.sermon.media.embed) {
                    this.selectedType = 'embed'
                    return null
                }
                if (this.sermon.media.video) {
                    this.selectedType = 'video'
                    return null
                }
                if (this.sermon.media.audio) {
                    this.selectedType = 'audio'
                    return null
                }

            })
            return null
        }
    },
    computed: {
        poster: function() {
            let imageUrl = ''

            if (this.sermon.series.image) {
                imageUrl = this.sermon.series.image
            }
            if (this.sermon.media.image) {
                imageUrl = this.sermon.media.image
            }

            return imageUrl
        },

        getWindowWidth: function() {
            if (typeof this.windowWidth === 'undefined') {
                return window.innerWidth
            }

            return this.windowWidth
        }
    }
}
</script>

<style lang="scss" scoped>
.sermon-player-wrap {
    background-color: rgba(35, 35, 35, 1);
    color: rgba(255, 255, 255, 1);
    border-bottom: 1px solid rgba(155, 155, 155, 0.16);
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);

    .sermon-player {
        width: 100%;
    }
}
.sermon-title {
    h2 {
        color: rgba(255, 255, 255, 1) !important;
    }
}
</style>
