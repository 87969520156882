import videojs from 'video.js';
import videojsYoutube from 'videojs-youtube';
import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.cjs';
import 'videojs-mux';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';

require('@silvermine/videojs-airplay')(videojs);
require('@silvermine/videojs-chromecast')(videojs, { preloadWebComponents: true });
import CastLoader from './CastLoader.js';

export const trackPlayerEventsMixin = {
    methods: {
        registerEvents() {
            // Turn off event tracking to prevent overloading the system
            window.Vue.nextTick(
                function() {
                    var self = this
                    // TODO: have to have a reference to self.player here so the computed property gets built
                    if (self.player) {
                    // TODO: commented out temporarily because the event listener is making too many requests
                    //     self.player.on(['play', 'pause', 'seeked', 'ended'], function(event) {
                    //         self.trackEvent(event)
                    //     })
                    }

                }.bind(this)
            )
        },
        trackEvent(event) {
            if (navigator.sendBeacon) {
                navigator.sendBeacon(
                    `//api.${window.base_domain}/logger/player-event`,
                    this.prepareEventData(event)
                )
            } else {
                window.axios
                    .post(
                        `//api.${window.base_domain}/logger/player-event`,
                        this.prepareEventData(event),
                        { withCredentials: true }
                    )
                    .catch(error => {
                        console.log(
                            `There was an error: ${error.response.statusText}`
                        )
                    })
            }
        },
        prepareEventData(event) {
            let data = new FormData()
            data.append('type', event.type)
            data.append('src', this.src)
            data.append('current_time', event.target.player.currentTime())
            data.append('total_time', event.target.player.duration())
            data.append(
                'fullscreen_active',
                event.target.player.isFullscreen()
            )
            data.append(
                'fullscreen_enabled',
                event.target.player.controlBar.fullscreenToggle.enabled_
            )
            data.append('pip_active', event.target.player.isInPictureInPicture())
            data.append('volume', event.target.player.volume())
            data.append('speed', event.target.player.playbackRate())
            return data
        }
    },
    computed: {
        player() {
            if (!window.videojs) {
                window.videojs = videojs;
                CastLoader.load();
            }
            if (this.src && this.$refs[this.src]) {
                let options = {
                    controls: true,
                    techOrder: ["chromecast","html5"],
                    plugins: {
                        chromecast: {
                            addButtonToControlBar: true
                        },
                        airPlay: {
                            addButtonToControlBar: true
                        }
                    }
                };
                if (this.src.indexOf('youtube.com') > -1) {
                    options = {
                        "techOrder": ["youtube"],
                        "sources": [
                            { "type": "video/youtube", "src": this.src}
                        ]
                    };
                }
                if (this.src.indexOf('vimeo.com') > -1) {
                    options = {
                        "techOrder": ["vimeo"],
                        "sources": [
                            { "type": "video/vimeo", "src": this.src}
                        ],
                        "vimeo": { "color": "#176BFB"}
                    }
                }

                if (this.autoplay) {
                    options.autoplay = this.autoplay;
                }

                if (this.loop) {
                    options.loop = this.loop;
                }

                if (this.live) {
                    options.plugins = {
                      mux: {
                        debug: false,
                        data: {
                          env_key: process.env.MUX_KEY,
                          sub_property_id: this.siteid,
                          video_id: this.streamingid,
                          video_title: this.streamingtitle,
                          player_name: 'Live Video Player',
                          player_init_time: Date.now()
                        }
                      }
                    }
                }

                let player = window.videojs(this.$refs[this.src],options);
                player.hlsQualitySelector({
                    displayCurrentQuality: true,
                });

                // If a quality selector is on the page update the quality selector for Audio players, as it only allows selection of video quality
                if (this.$options.name === 'Audio' && $('.vjs-quality-selector').length > 0) {
                    // Remove the quality selector
                    player.ready(() => {
                        player.$('.vjs-quality-selector').remove();
                    });

                    // Set the video to the lowest quality to reduce the file sizes
                    player.on('loadedmetadata', () => {
                        var qualityLevels = player.qualityLevels();
                        player.hlsQualitySelector.setQuality(qualityLevels[qualityLevels.length -1].height);
                    });
                }

                if (typeof player.airPlay !== "undefined") {
                    player.airPlay();
                }
                if (typeof player.chromecast !== "undefined") {
                    player.chromecast();
                }

                if (this.poster){
                    player.poster(this.poster);
                }
                return player;
            }
            return null;
        }
    }
}
