<template>
    <div class="interactive-note-wrap">
        <div class="interactive-note-meta-wrap">
            <div>
                <simple-svg
                    :filepath="this.getSvgFilePath('document')"
                    width="20px"
                    height="20px"
                    fill="var(--blue)"
                ></simple-svg>
                <h5>Sermon Notes</h5>
            </div>
            <div>
                <a href="#" class="autofill">Auto Fill</a>
                <a href="#" class="clearnotes">Clear Notes</a>
                <a href="#" class="saveAsPdf">Print PDF</a>
            </div>
        </div>
        <form id="notes" class="form-inline" v-html="note"></form>
    </div>
</template>

<script>
import JQuery from 'jquery'
let $ = JQuery

export default {
    props: ['note'],

    methods: {
        isCorrect: function(input) {
            const testAnswer = $.trim(input.val().toLowerCase()).replace(
                /[.'"â€™,-/#!$%^&*;:{}=\-_`~()]/g,
                ''
            )
            const realAnswer = input
                .attr('data-answer')
                .toLowerCase()
                .replace(/[.'"â€™,-/#!$%^&*;:{}=\-_`~()]/g, '')
            return testAnswer == realAnswer
        },
        localStorageKeyName: function() {
            let answers = ''
            $('#notes input.blank').each(function() {
                answers += $(this).attr('data-answer')
            })
            return answers.toLowerCase()
        },
        supportsLocalStorage: function() {
            return (
                !!window.localStorage &&
                typeof localStorage.getItem === 'function' &&
                typeof localStorage.setItem === 'function' &&
                typeof localStorage.removeItem === 'function'
            )
        },
        saveToLocalStorage: function() {
            let notesData = { blank: [], pnote: [] }
            $('input.blank').each(function(i) {
                notesData.blank[i] = $(this).val()
            })
            $('textarea.pnoteText').each(function(i) {
                notesData.pnote[i] = $(this).val()
            })
            localStorage.setItem(
                this.localStorageKeyName(),
                JSON.stringify(notesData)
            )
        },
        saveAsPdf: function() {
            const notes_text = $('form#notes').clone()[0]

            $(notes_text)
                .find('.blank')
                .each(function() {
                    let answer = $(this).val()
                    $(this).replaceWith(`<strong>${answer}</strong>`)
                })
            $(notes_text)
                .find('.pnoteText')
                .each(function() {
                    let answer = $(this).val()
                    $(this).replaceWith(
                        `<div style='white-space: pre-wrap;'><em>${answer}</em></div>`
                    )
                })

            let title = $('.sermon-title')
                .first()
                .text()
            let today_pretty = new Date().toDateString()

            let mywindow = window.open('', 'new div', 'height=800,width=800')
            mywindow.document.write('<html><head><title>Sermon Notes</title>')
            /* optional stylesheet */
            mywindow.document.write(
                '<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css">'
            )
            mywindow.document.write('</head><body><div class="container mt-3">')
            mywindow.document.write('<h1>' + title + '</h1>')
            mywindow.document.write('<h2>' + today_pretty + '</h2><hr>')
            mywindow.document.write($(notes_text).html())
            mywindow.document.write('</div></body></html>')
            setTimeout(function() {
                mywindow.print()
                mywindow.close()
            }, 50)

            return true
        },
        interactiveNoteEvents: function() {
            let self = this
            $('input.blank')
                .on('keyup', function() {
                    // after every keystroke
                    let input = $(this)
                    let answer = input.val()

                    if (
                        answer[answer.length - 1] == ' ' &&
                        input.attr('data-answer').indexOf(' ') == -1
                    ) {
                        // if last character was a space (and answer doesn't contain spaces), skip to next field on line
                        input.next().focus()
                    }
                    if (self.isCorrect(input)) {
                        input.addClass('correct-answer')
                    } else {
                        input.removeClass('correct-answer wrong-answer')
                    }
                })
                .on('blur', function() {
                    // after the field is deselected
                    let input = $(this)
                    if (self.isCorrect(input)) {
                        input
                            .addClass('correct-answer')
                            .val(input.attr('data-answer'))
                    } else if (input.val().length) {
                        input.addClass('wrong-answer')
                    }
                })
                .on('focus', function() {
                    // when the field is selected
                    let input = $(this)
                    if (input.hasClass('wrong-answer'))
                        input.val('').removeClass('wrong-answer')
                })
                .on('keypress', function(e) {
                    let code = e.keyCode || e.which
                    if (code == 13) return false
                })

            // auto-grow textarea with content
            $('.pnoteText')
                .on('keyup', function() {
                    const textarea = $(this)
                    let rows = textarea.val().split(/\r*\n/).length
                    if (rows < 3) {
                        rows = 3
                    }
                    textarea.attr('rows', rows)
                })
                .trigger('keyup')

            // auto-fill functionality
            $('.autofill')
                .first()
                .on('click', function() {
                    if (
                        !confirm(
                            'The best way to learn the message is by filling out the notes yourself. This function is only provided as a convienience.\n\nAre you sure you want to fill out these notes automatically?'
                        )
                    )
                        return false

                    $('#notes input.blank')
                        .each(function() {
                            $(this)
                                .addClass('correct-answer')
                                .removeClass('wrong-answer')
                                .val($(this).attr('data-answer'))
                        })
                        .first()
                        .trigger('change')
                    self.saveToLocalStorage()
                    return false
                })

            $('.clearnotes').on('click', function() {
                if (
                    !confirm(
                        'This will clear all blanks and personal notes. Are you sure you want to do this?'
                    )
                )
                    return false
                $('#notes input.blank')
                    .val('')
                    .trigger('keyup')
                $('.pnoteText')
                    .val('')
                    .trigger('blur')
                localStorage.setItem(self.localStorageKeyName(), null)
                return false
            })

            // handle the email button
            $('#emailButton').on('click', function(e) {
                $('#formAction').val('email')
                let email = prompt(
                    'What email address would you like to send this to?'
                )
                if (!!email && email.length) {
                    $('#formEmail').val(email)
                } else {
                    e.preventDefault()
                }
            })

            // handle the download button
            $('.saveAsPdf').on('click', function() {
                self.saveAsPdf()
            })

            // save and restore data auto-magically
            if (self.supportsLocalStorage()) {
                // restore previously saved data if any exists
                let notesData = localStorage.getItem(self.localStorageKeyName())
                if (notesData) {
                    notesData = JSON.parse(notesData)
                    if (typeof notesData == 'object' && notesData != null) {
                        $('input.blank').each(function(i) {
                            if (
                                typeof notesData.blank[i] == 'string' &&
                                !notesData.blank[i].length
                            )
                                return

                            $(this).val(notesData.blank[i])

                            if (self.isCorrect($(this)))
                                $(this).addClass('correct-answer')
                            else $(this).addClass('wrong-answer')
                        })
                        $('textarea.pnoteText').each(function(i) {
                            $(this).val(notesData.pnote[i])
                            if (notesData.pnote[i].length) {
                                $(this)
                                    .removeClass('hide')
                                    .trigger('keydown')
                                $(this)
                                    .prev()
                                    .addClass('hide')
                            }
                            const textarea = $(this)
                            let rows = textarea.val().split(/\r*\n/).length
                            if (rows < 3) {
                                rows = 3
                            }
                            textarea.attr('rows', rows)
                        })
                    }
                }

                // save data as inputs/textareas are changed
                $('input.blank, textarea.pnoteText').on('blur', function() {
                    self.saveToLocalStorage()
                })
            }
        },
        initializeNotes: function() {
            this.$nextTick(function() {
                if (this.note) {
                    this.interactiveNoteEvents()
                }
            })
        }
    },
    mounted() {
        this.initializeNotes()
    },
    watch: {
        note: function() {
            this.initializeNotes()
        }
    }
}
</script>

<style lang="scss">
.interactive-note-wrap {
    .interactive-note-meta-wrap {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid rgba(216, 216, 216, 1);
        padding: 10px 15px;
        div {
            display: flex;
        }
        h5 {
            text-transform: uppercase;
            margin-left: 10px;
        }
        a {
            color: #ffffff;
            text-decoration: underline;
            margin-right: 14px;
            &:last-of-type {
                margin-right: 0px;
            }
            &:hover {
                color: var(--blue);
            }
        }
    }
    form {
        padding: 10px 15px;
    }
}
#notes {
    .pnoteText {
        display: block;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding: 2em;
        border: 0;
        resize: none;
        outline: none;
        font-family: inherit;
        font-size: 1em;
        font-weight: inherit;
        box-shadow: none;
        color: #000000;
    }
    .blank {
        padding: 0;
        margin: 0;
        border: 0;
        border-bottom: 1px solid #ffffff;
        border-radius: 0;
        background-color: transparent;
        outline: none;
        text-align: center;
        font-family: inherit;
        font-size: 1em;
        height: 1.2em;
        margin-top: -3px;
        font-weight: 700;
        color: #c90;
        &:focus-within {
            box-shadow: none;
        }
    }
    .correct-answer {
        border-color: #090;
        color: #090;
    }
    .wrong-answer {
        border-color: #f00;
        color: #f00;
    }
    p {
        width: 100%;
        line-height: 1.3em;
    }
    div {
        width: 100%;
        margin-bottom: 1em;
    }
}
</style>
