<template>
    <div class="input-group">
        <div class="input-group-prepend">
            <span @click="searchFocus()" class="input-group-text border-0">
                <simple-svg :filepath="this.getSvgFilePath('search')" height="19px" fill="#1F2126"></simple-svg>
            </span>
        </div>

        <input ref="searchValueRef" v-model="searchValue" type="text" class="form-control search border-0" id="search" name="q" placeholder="Search">

        <div class="input-group-append">
            <span @click="searchReset()" class="input-group-text border-0">
                <span :class="{ 'd-none': searchValue === '' }">
                    <simple-svg :filepath="this.getSvgFilePath('close')" height="14px" fill="#1F2126"></simple-svg>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchValue: ''
            }
        },
        methods: {
            searchFocus() {
                this.$refs.searchValueRef.focus();
            },
            searchReset() {
                this.searchValue = '';
                this.searchFocus();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .input-group-append,
    .input-group-prepend {
        cursor: pointer;
        height: 50px;

        .input-group-text {
            background-color: #ffff;
        }
    }

    .search {
        height: 50px;
        margin: 0;
        padding: 0;
        text-align: left;

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            color: #A5A6A8;
        }
    }
</style>
