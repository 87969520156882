<template>
    <div
        v-if="showCountdown"
        class="sermon-countdown"
        :class="{'sermon-countdown-dark': dark}"
    >
        <div class="sermon-countdown-watch-text">
            Watch live in
        </div>

        <div class="sermon-countdown-number-container">
            <div class="sermon-countdown-number">
                {{days}}
            </div>

            <div class="sermon-countdown-text">
                DAYS
            </div>
        </div>

        <div class="sermon-countdown-number-container">
            :
        </div>

        <div class="sermon-countdown-number-container">
            <div class="sermon-countdown-number">
                {{hours}}
            </div>

            <div class="sermon-countdown-text">
                HOURS
            </div>
        </div>

        <div class="sermon-countdown-number-container">
            :
        </div>

        <div class="sermon-countdown-number-container">
            <div class="sermon-countdown-number">
                {{minutes}}
            </div>

            <div class="sermon-countdown-text">
                MINUTES
            </div>
        </div>

        <div class="sermon-countdown-number-container">
            :
        </div>

        <div class="sermon-countdown-number-container">
            <div class="sermon-countdown-number">
                {{seconds}}
            </div>

            <div class="sermon-countdown-text">
                SECONDS
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dark: Boolean,
        currentStream: Object,
        hasCountdown: {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            days: '00',
            hours: '00',
            minutes: '00',
            seconds: '00',
            endTimeInMilliseconds: 0,
            startTimeInMilliseconds: 0,
            countdownTimeout: undefined,
            updateTimeout: undefined,
            checkingUpdates: false,
            streams: undefined,
            maxConnectionCount: 10000,
            currentConnectionCount: 0
        }
    },
    created() {
        // Let the dashboard know each time a streaming view has been loaded
        window.parent.postMessage(['streamingViewLoaded'], '*');

        // Start the countdown
        this.initCountdown();

        this.checkStreamTimes();
    },
    watch: {
        /**
         * Listen for the current stream to change and then restart the countdown
         */
        currentStream() {
            this.initCountdown();
        },

        /**
         * Prevent users from making too many ajax requests requests before reloading the page
         */
        currentConnectionCount() {
            if (this.currentConnectionCount >= this.maxConnectionCount) {
                window.location.reload();
            }
        }
    },
    computed: {
        /**
         * Only show the countdown timer if the parent component wants it displayed and there is still time remaining
         *
         * @returns {boolean}
         */
        showCountdown() {
            return this.hasCountdown &&
                   (this.days > 0 ||
                    this.hours > 0 ||
                    this.minutes > 0 ||
                    this.seconds > 0);
        }
    },
    methods: {
        /**
         * Start the countdown with the current stream
         */
        initCountdown() {
            // If there is a stream, update the start time, end time, and countdown
            if (this.currentStream) {
                this.endTimeInMilliseconds = Date.parse(new Date(this.currentStream.end.carbon));
                this.startTimeInMilliseconds = Date.parse(new Date(this.currentStream.start.carbon));

                this.updateCountdown();
            } else {
                // Empty out the start & end times when there is no stream so we can properly process the end
                this.endTimeInMilliseconds = 0;
                this.startTimeInMilliseconds = 0;
            }
        },

        /**
         * Set the countdown display values
         */
        updateCountdown() {
            // Milliseconds between the start time and the current time
            var timeDiff = this.startTimeInMilliseconds - Date.parse(new Date());

            // If he stream has not started yet, so continue to update the countdown
            if (timeDiff > 0) {
                // Calculate the seconds, minutes, hours, and days until timeDiff === 0
                this.seconds = this.formatNumber(Math.floor(timeDiff / 1000 % 60));
                this.minutes = this.formatNumber(Math.floor(timeDiff / 1000 / 60 % 60));
                this.hours = this.formatNumber(Math.floor(timeDiff / (1000 * 60 * 60) % 24));
                this.days = this.formatNumber(Math.floor(timeDiff / (1000 * 60 * 60 * 24)));

                // Because JS is single threaded, we can't guarantee setTimeout is called at the exact time
                // so by updating every 1/4 second and always comparing against the actual clock (instead
                // of just subtracting a second from a global), we ensure the countdown is always accurate
                this.countdownTimeout = setTimeout(() => this.updateCountdown(), 250);
            } else {
                // If the stream has started stop trying to update the countdown
                if (typeof this.countdownTimeout !== 'undefined') {
                    clearTimeout(this.countdownTimeout);
                    this.countdownTimeout = undefined;
                }

                // Zero out all countdown values so we know to stop showing the countdown
                this.seconds = this.minutes = this.hours = this.days = 0;

                // Tell the parent component the current stream has started
                this.$emit('stream-started');

                // Now wait until the stream ends
                this.waitForStreamToEnd();
            }
        },

        /**
         * Hide the countdown while the stream is live
         */
        waitForStreamToEnd() {
            // Milliseconds between the start & end times and the current time
            var startTimeDiff = this.startTimeInMilliseconds - Date.parse(new Date());
            var endTimeDiff = this.endTimeInMilliseconds - Date.parse(new Date());

            // If the start time is in the past and the end time is in the future, keep waiting
            if (startTimeDiff <= 0 && endTimeDiff > 0) {
                this.countdownTimeout = setTimeout(() => this.waitForStreamToEnd(), 1000);
            } else {
                // If the stream has ended stop waiting for it to end
                if (typeof this.countdownTimeout !== 'undefined') {
                    clearTimeout(this.countdownTimeout);
                    this.countdownTimeout = undefined;
                }

                // Tell the parent component that the stream has ended and let checkStreamTimes load up the next stream
                this.$emit('stream-ended');
            }
        },

        /**
         * Ensure the countdown numbers are 2 digits long
         *
         * @param {Int} num - number displayed in the countdown
         * @returns {string}
         */
        formatNumber(num) {
            return num.toString().padStart(2, '0');
        },

        /**
         * Check the server to see if any stream start or end times have changed
         */
        checkStreamTimes() {
            // Only allow 1 ajax check at a time
            if (this.checkingUpdates) {
                return;
            }

            this.checkingUpdates = true;

            // The scheduled stream can start or stop at any time and only the server knows when this happens
            axios({
                url: `//api.${window.base_domain}/${window.Church.slug}/streaming/streams`,
                method: 'get'
            })
            // Ajax success
            .then(response => {
                this.streams = response.data.data;
            })
            // Ajax error, most likely due to there being no more streams
            .catch(() => {
                this.streams = null;
            })
            // Ajax complete
            .then(() => {
                // Let the dashboard know each time the /streaming/streams endpoint has been loaded
                window.parent.postMessage(['streamTimesChecked'], '*');

                // Keep track of the number of times a client has loaded /streaming/streams so we can prevent spamming
                this.currentConnectionCount++;

                this.checkingUpdates = false;

                if (typeof this.updateTimeout !== 'undefined') {
                    clearTimeout(this.updateTimeout);
                    this.updateTimeout = undefined;
                }

                window.parent.postMessage(['allStreamsUpdated', this.streams], '*');

                if (this.streams && this.streams[0]) {
                    this.$emit('stream-updated', this.streams[0]);
                } else {
                    this.$emit('stream-updated', null);
                }

                // Recheck every 15 seconds
                this.updateTimeout = setTimeout(() => this.checkStreamTimes(), 15000);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');

.sermon-countdown {
    background: #ECECEE;
    border-top: 3px solid #353C43;
    color: #232323;
    text-align: center;
    white-space: nowrap;

    // Switch the colors for a dark theme
    &.sermon-countdown-dark {
        background: #353C43;
        border-color: #3399FF;
        color: #fff;
    }

    // Text displayed above the countdown timer
    .sermon-countdown-watch-text {
        margin: 1px 0 10px 0;
    }

    // Keep countdown numbers, separators, and text styled & aligned
    .sermon-countdown-number-container {
        display: inline-block;
        font-size: 55px;
        font-weight: bold;
        line-height: 36px;
        vertical-align: top;
    }

    // Numbers that actually count down to the stream
    .sermon-countdown-number {
        // Use "Lato" instead of the default ("Monsterrat") to keep the changing numbers from jumping around
        font-family: "Lato", monospace;
        margin: 0 4px;
    }

    // Text displayed under each countdown number (days, hours, minutes, seconds)
    .sermon-countdown-text {
        font-size: 13px;
    }

    // Reduce font sizes so the countdown will stay on a single line for small browsers
    @include media-breakpoint-down(sm) {
        .sermon-countdown-watch-text {
            margin-bottom: 5px;
        }

        .sermon-countdown-number-container {
            font-size: 32px;
            line-height: 25px;
        }

        .sermon-countdown-text {
            font-size: 10px;
            font-weight: normal;
        }
    }
}
</style>
