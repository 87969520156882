<template>
    <div class="sermon-player" >
        <!-- regular video -->
        <video
            @load="posterLoaded"
            download
            :autoplay="this.autoplay"
            :muted="this.autoplay === 'muted'"
            preload="none"
            :poster="poster"
            :src="src"
            :live="live"
            :streamingid="streamingid"
            :streamingtitle="streamingtitle"
            :siteid="siteid"
            controls
            playsinline
            :ref="src"
            class="video-js vjs-fluid vjs-theme-fantasy"
            data-setup='{ "playbackRates": [0.5, 0.75, 1, 1.5, 1.75, 2] }'
        >
            <source :src=src >
        </video>
    </div>
</template>

<script>
import { trackPlayerEventsMixin } from '../../mixins/trackPlayerEventsMixin'
import { EventBus } from '../../mixins/EventBus';

export default {
    mixins: [trackPlayerEventsMixin],
    name: 'Video',
    props: ['autoplay', 'loop', 'poster', 'src','live','streamingid','streamingtitle','siteid'],
    mounted() {
        this.registerEvents()

        // Send the player object to the parent component
        EventBus.$emit('player', this.player);
    },
    watch: {
        src: function() {
            this.registerEvents()
        }
    },
    methods: {
        posterLoaded() {
            if (typeof window.sermonCloudResizeHeight == 'function') {
                window.sermonCloudResizeHeight()
            }
        }
    }
}
</script>
