function loadScriptAsync(url) {
    return new Promise((resolve, reject) => {
        let r = false, t = document.getElementsByTagName('script')[0], s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = url;
        s.async = true;
        s.onload = s.onreadystatechange = function () {
            //@ts-ignore
            if (!r && (!this.readyState || this.readyState === 'complete')) {
                r = true;
                resolve(this);
            }
        };
        s.onerror = s.onabort = reject;
        if (t && t.parentNode) {
            t.parentNode.insertBefore(s, t);
        }
    });
}
const SENDER_SDK_URL = '//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';
class CastLoader {
    static load() {
        return new Promise((resolve, reject) => {
            // @ts-ignore
            window['__onGCastApiAvailable'] = (isAvailable) => CastLoader.onGCastApiAvailable(isAvailable, resolve);
            CastLoader.loadCastSDK()
                .then(() => {
                console.warn('Cast sender lib has been loaded successfully');
            })
                .catch(e => {
                console.warn('Cast sender lib loading failed', e);
                reject(e);
            });
        });
    }
    static loadCastSDK() {
        if (window['cast'] && window['cast']['framework']) {
            return Promise.resolve();
        }
        return loadScriptAsync(SENDER_SDK_URL);
    }
    static onGCastApiAvailable(isAvailable, resolve) {
        if (isAvailable) {
            resolve();
        }
        else {
            console.warn(`Google cast API isn't available yet`);
        }
    }
}


export default CastLoader;